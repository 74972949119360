import $ from 'jquery'

const $doc = $(document)
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
  } else {
    $hamburger.attr('aria-expanded', 'false')
  }
}
function hideMobileNav(event) {
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
  } else {
    $hamburger.attr('aria-expanded', 'false')
  }
}

function openMobileDropdowns() {
  const menu_item = $(this).parent('.menu-item-has-children');
  const submenu = $(this).next('.sub-menu');
  const arrow = $(this).find('.icon');
  submenu.slideToggle(500);
  $(this).toggleClass('is-active');
  arrow.toggleClass('is-active');
  $('.sub-menu').not(submenu).slideUp(500);
  $(this).parent().attr('aria-expanded',
    $(this).parent().attr('aria-expanded')=='false' ? 'true' : 'false'
  );
  $('.js-mobile-nav .menu-item-has-children').not(menu_item).attr('aria-expanded', 'false');
  $('.js-mobile-nav .menu-item-has-children .dropdown-toggle').not(this).removeClass('is-active');
  $('.js-mobile-nav .menu-item-has-children .icon').not(this).removeClass('is-active');
}

export default function initMobileNav(){
  $doc.on('click', '.js-hamburger', showMobileNav )
  $doc.on('click', '.js-mobile-nav .menu-item a', hideMobileNav )
  $doc.on('keydown', function(event) {
    if ( $mobileNav.hasClass('is-active') && (event.key == "Escape") ) {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
    }
  })
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ( $mobileNav.hasClass('is-active') ) {
        $hamburger.attr('aria-expanded', 'false')
        $mobileNav.toggleClass('is-active')
        $hamburger.toggleClass('is-active')
      }
    }
  })
  $doc.on('click', '.js-mobile-nav .menu-item-has-children .dropdown-toggle', openMobileDropdowns )
}
