import 'slick-carousel';
import 'magnific-popup';

import initGreenSock from '/lib/greensock'
import initHeadroom from '/lib/headroom'
import initMobileNav from '/lib/mobile-nav'
import initA11yFunctions from '/lib/accessibility'

initGreenSock()
initHeadroom()
initMobileNav()
initA11yFunctions()

$(document).ready(function(e){
    $('.wysiwyg-editor').find('iframe').each(function() {
        $(this).wrap( '<div class="iframe-container"></div>' );
    })
});
